import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { WIX_FORUM as forumAppDefId } from '@wix/app-definition-ids';
import { getComponentByAppId } from './find-component';
import {
  BUTTON_BORDER_RADIUS,
  POST_BORDER_RADIUS,
  HEADER_HEIGHT,
  MAIN_PAGE_HEADER_HEIGHT,
  wixPMobile,
} from '@wix/communities-forum-client-commons';

export const setDefaultForumStyles = async (appToken: string, sdk: FlowEditorSDK) => {
  const appData = await sdk.tpa.app.getDataByAppDefId(appToken, forumAppDefId);
  const component = await getComponentByAppId(appToken, sdk, appData.applicationId);
  const compRef = await sdk.components.getById(appToken, { id: component.id });

  if (!compRef) {
    // No component ref found. No default styles for you :(
    return Promise.resolve();
  }

  return sdk.document.tpa.setStyleParams(appToken, {
    compRef,
    styleParams: [
      {
        type: 'number',
        key: BUTTON_BORDER_RADIUS,
        param: {
          value: 8,
        },
      },
      {
        type: 'number',
        key: POST_BORDER_RADIUS,
        param: {
          value: 8,
        },
      },
      {
        type: 'number',
        key: wixPMobile(HEADER_HEIGHT),
        param: {
          value: 100,
        },
      },
      {
        type: 'number',
        key: wixPMobile(MAIN_PAGE_HEADER_HEIGHT),
        param: {
          value: 100,
        },
      },
    ],
  });
};
