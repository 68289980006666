import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { WIX_FORUM as forumAppDefId } from '@wix/app-definition-ids';

const OLD_LOGIN_BAR_WIDGET = '14760fd4-9973-682c-16fa-797dfb1f2880';
const OLD_DISCUSSIONS_PAGE = '1522d440-2b99-8f05-12b9-6e405ce46abc';
const OLD_FORUM_LIKES_PAGE = '14f51b0d-8913-052b-0af2-960a9605790b';

export const removeOldComponents = async (editorSDK: FlowEditorSDK, appToken: string) => {
  return Promise.all([
    removeOldLoginWidget(editorSDK, appToken),
    removePageComponent(editorSDK, appToken, OLD_DISCUSSIONS_PAGE),
    removePageComponent(editorSDK, appToken, OLD_FORUM_LIKES_PAGE),
  ]);
};

const getComponentsData = async (editorSDK: FlowEditorSDK, appToken: string) => {
  const componentsRef = await editorSDK.document.components.getAllComponents(appToken);

  const componentsData = await Promise.all(
    componentsRef.map((componentRef) =>
      editorSDK.document.components.data.get(appToken, { componentRef }),
    ),
  );

  return { componentsRef, componentsData };
};

const removeOldLoginWidget = async (editorSDK: FlowEditorSDK, appToken: string) => {
  const { componentsRef, componentsData } = await getComponentsData(editorSDK, appToken);

  return Promise.all(
    componentsData.map((componentData: any, index) => {
      if (
        componentData?.appDefinitionId === forumAppDefId &&
        componentData?.widgetId === OLD_LOGIN_BAR_WIDGET
      ) {
        return editorSDK.document.components.remove(appToken, {
          componentRef: componentsRef[index],
        });
      }

      return Promise.resolve();
    }),
  );
};

const removePageComponent = async (
  editorSDK: FlowEditorSDK,
  appToken: string,
  widgetId: string,
) => {
  const { componentsRef, componentsData } = await getComponentsData(editorSDK, appToken);

  return Promise.all(
    componentsData.map(async (componentData: any, index) => {
      if (
        componentData?.appDefinitionId === forumAppDefId &&
        componentData?.widgetId === widgetId
      ) {
        await editorSDK.document.components.remove(appToken, {
          componentRef: componentsRef[index],
        });

        const pageRef = await editorSDK.document.components.getPage(appToken, {
          componentRef: componentsRef[index],
        });

        if (pageRef) {
          return editorSDK.document.pages.remove(appToken, { pageRef });
        }
      }

      return Promise.resolve();
    }),
  );
};
